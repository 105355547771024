body {
  // colors
  --color-primary: #4353ff;
  --color-primary-bright: #5064ff;

  --color-warn: #fb4f4f;
  --color-warn-light: #fc6767;
  --color-warn-dark: #fa2f2f;
  --color-yellow: #ffd448;
  --color-yellow-light: #ffe15e;
  --color-yellow-dark: #ffca28;
  --color-orange: #ffa02e;
  --color-orange-light: #ffb337;
  --color-orange-dark: #ff8b0f;
  --color-purple: #8a30e7;
  --color-purple-light: #a13aec;
  --color-purple-dark: #7110e1;
  --color-green: #12d090;
  --color-green-light: #16d9a6;
  --color-green-dark: #00c578;
  --color-blue: #2584ff;
  --color-blue-light: #2c9dff;
  --color-blue-dark: #076aff;
  --color-teal: #20d4e3;
  --color-teal-light: #26dde9;
  --color-teal-dark: #04cadc;
  --color-pink: #f02e8f;
  --color-pink-light: #f53cb1;
  --color-pink-dark: #ec0f77;

  --color-white: #ffffff;
  --color-grey-10: #f8fafc;
  --color-grey-20: #f1f5f8;
  --color-grey-30: #dee8f1;
  --color-grey-40: #bfcfe1;
  --color-grey-50: #8595a8;
  --color-grey-50-rgba-value: 133, 149, 168;
  --color-grey-60: #5d6e82;
  --color-grey-70: #495465;
  --color-grey-80: #1f232e;
  --color-grey-90: #111319;

  --shadow: 0px 8px 32px 0px #1f232e26;
  --shadow-medium: 0px 8px 32px 0px #1f232e49;
  --shadow-small: 0px 3px 16px 0px #1f232e26;
  --reversed-shadow: 0px -8px 32px 0px #1f232e26;
  --shadow-strong: 0px 8px 32px rgba(0, 0, 0, 0.5);

  --easing-gradient-black: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.987) 8.1%,
    rgba(0, 0, 0, 0.951) 15.5%,
    rgba(0, 0, 0, 0.896) 22.5%,
    rgba(0, 0, 0, 0.825) 29%,
    rgba(0, 0, 0, 0.741) 35.3%,
    rgba(0, 0, 0, 0.648) 41.2%,
    rgba(0, 0, 0, 0.55) 47.1%,
    rgba(0, 0, 0, 0.45) 52.9%,
    rgba(0, 0, 0, 0.352) 58.8%,
    rgba(0, 0, 0, 0.259) 64.7%,
    rgba(0, 0, 0, 0.175) 71%,
    rgba(0, 0, 0, 0.104) 77.5%,
    rgba(0, 0, 0, 0.049) 84.5%,
    rgba(0, 0, 0, 0.013) 91.9%,
    rgba(0, 0, 0, 0) 100%
  );

  --easing-gradient-white: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.987) 8.1%,
    rgba(255, 255, 255, 0.951) 15.5%,
    rgba(255, 255, 255, 0.896) 22.5%,
    rgba(255, 255, 255, 0.825) 29%,
    rgba(255, 255, 255, 0.741) 35.3%,
    rgba(255, 255, 255, 0.648) 41.2%,
    rgba(255, 255, 255, 0.55) 47.1%,
    rgba(255, 255, 255, 0.45) 52.9%,
    rgba(255, 255, 255, 0.352) 58.8%,
    rgba(255, 255, 255, 0.259) 64.7%,
    rgba(255, 255, 255, 0.175) 71%,
    rgba(255, 255, 255, 0.104) 77.5%,
    rgba(255, 255, 255, 0.049) 84.5%,
    rgba(255, 255, 255, 0.013) 91.9%,
    rgba(255, 255, 255, 0) 100%
  );

  // sizes
  --spacing: 20px;
  --border-radius: 12px;
}
