.settings-page {
  padding-bottom: 200px;
  padding-top: 0;

  @media (max-width: 900px) {
    padding-bottom: 90px;
  }
}

.settings-title {
  font-weight: 900;
  text-align: center;
  font-size: 32px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 600px) {
    font-size: 48px;
  }
}

.settings-subtitle {
  color: var(--color-grey-60);
  text-align: center;
  max-width: 480px;
  margin: 8px auto 48px;

  b {
    color: var(--color-grey-80);
  }

  @media (min-width: 600px) {
    font-size: 21px;
  }
}

.settings-group-title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 4px;
  &.with-text {
    margin-bottom: 5px;
  }

  @media (min-width: 600px) {
    font-size: 24px;
    margin-bottom: 20px;
    &.with-text {
      margin-bottom: 10px;
    }
  }
}

.header-image-container {
  height: 240px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: -20px;

  @media (max-width: 600px) {
    margin-top: -20px;
    margin-bottom: -5px;
  }

  img {
    height: 100%;
  }

  app-animated-settings-icon {
    margin: 23px auto;
    display: block;
  }
}

.small-settings-box {
  display: flex;
  align-items: center;
  font-weight: bold;
  border: 1px solid var(--color-grey-30);
  height: 64px;
  padding: 0 20px;

  &:not(:last-child) {
    border-bottom: none;
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  span {
    flex: 1 1 auto;
  }

  > app-svg {
    margin-right: 10px;
  }

  &.disabled-look {
    background-color: var(--color-grey-10);
    color: var(--color-grey-50);
  }
}
