.radio {
  position: relative;

  input {
    display: none;

    + label {
      padding: 5px 0 5px 28px;
      display: block;
      position: relative;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
      }

      &:before {
        width: 18px;
        height: 18px;
        left: 0;
        border: 1px solid var(--color-primary);
        border-color: var(--color-grey-50);
        top: 8px;
      }

      &:after {
        background-color: var(--color-primary);
        left: 4px;
        width: 10px;
        height: 10px;
        opacity: 0;
        transform: scale(0.5);
        transition: all 0.2s ease;
        top: 12px;
      }
    }

    &:hover {
      + label {
        &:after {
          opacity: 0.2;
          transform: scale(2.2);
        }
      }
    }

    &:checked {
      + label {
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.fake-radio {
  padding: 5px 0 5px 28px;
  display: block;
  position: relative;
  cursor: pointer;
  color: var(--color-grey-60);
  font-weight: bold;

  &:before,
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  &:before {
    width: 18px;
    height: 18px;
    left: 0;
    border: 1px solid var(--color-primary);
    border-color: var(--color-grey-50);
    top: 8px;
  }

  &:after {
    background-color: var(--color-primary);
    left: 4px;
    width: 10px;
    height: 10px;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.2s ease;
    top: 12px;
  }

  &:hover {
    &:after {
      opacity: 0.2;
      transform: scale(2.2);
    }
  }

  &.active {
    color: var(--color-grey-80);
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

app-context-menu.disabled {
  pointer-events: none;
}

.menu-list-title {
  font-size: 12px;
  color: var(--color-grey-50);
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 10px;
}

.radio-box {
  input {
    display: none;

    + label {
      cursor: pointer;
      color: var(--color-grey-80);
      padding: 4px 0;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      line-height: 1;
      position: relative;

      app-svg {
        margin-right: 4px;
      }

      .section.dark & {
        color: var(--color-grey-50);
      }

      &:after,
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        border-radius: 8px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity 0.2s ease;
        transition: all 0.2s ease;
        height: 22px;
        width: 22px;
      }

      &:before {
        background-color: white;
        border: 1px solid var(--color-grey-30);

        .section.dark & {
          background-color: var(--color-grey-80);
          border: 1px solid var(--color-grey-70);
        }
      }

      &:after {
        background-color: white;
        mask-image: url('/assets/icons/check.svg');
        mask-size: contain;
        mask-position: center;
        opacity: 0;
      }
    }

    &:hover {
      + label {
        &:before {
          border-color: var(--color-grey-40);
        }
      }
    }

    &:checked {
      + label {
        color: var(--color-grey-90);
        .section.dark & {
          color: white;
        }

        app-svg {
          &.color-warn {
            color: var(--color-warn);
          }
          &.color-green {
            color: var(--color-green);
          }
        }

        &:before {
          border-color: var(--color-primary);
          background-color: var(--color-primary);
          .section.dark & {
            border-color: var(--color-primary);
            background-color: var(--color-primary);
          }
        }

        &:after {
          opacity: 1;
        }
      }
    }

    &:disabled {
      + label {
        &:before {
          border-color: var(--color-grey-40);
          background-color: var(--color-grey-30);
        }
      }
    }
  }

  &.reversed {
    input {
      display: none;

      + label {
        padding-left: 30px;
        &:before,
        &:after {
          right: auto;
          left: 0;
        }
      }
    }
  }
}

.selector-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selector {
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 12px;
  background-color: var(--color-grey-20);
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--color-grey-30);
  }

  .selector-item {
    flex: 1 1 auto;

    label {
      font-size: 16px;
      cursor: pointer;
      transition: all 0.2s ease;
      align-items: center;
      height: 100%;
      justify-content: center;

      position: relative;
      margin-right: 1px;
      padding: 12px 16px;
      background-color: transparent;
      border-radius: 8px !important;
      color: var(--color-grey-50);
      display: flex;
      flex-direction: column;

      &:hover {
        color: var(--color-grey-70);
      }

      span {
        line-height: 1;
      }

      app-svg {
        width: 32px;
        margin: -8px;
        display: block;
      }
    }

    input {
      display: none;

      &:checked,
      &.selected {
        + label {
          background-color: white;
          border-color: var(--color-grey-30);
          box-shadow: var(--shadow-small);
          z-index: 2;
          font-weight: bold;
          color: var(--color-primary);
        }
      }
    }

    &:first-child label {
      border-radius: 8px 0 0 8px;
    }

    &:last-child label {
      border-radius: 0 8px 8px 0;
      margin-right: 0;
    }
  }
}

.field-and-button {
  position: relative;

  .formfield {
    margin-top: 0;
    width: 100%;

    input,
    select,
    textarea,
    [fake-input] {
      // Super arbitrary, I know. Its fine for now as we only need it for gallery password setting field
      padding-right: 130px;
      + .after {
        .post-template {
          right: 90px;
        }
      }
    }
  }

  > button,
  > button:before,
  > button:after {
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
  }

  app-input-field {
    .formfield {
      margin-right: -10px;
    }
    &.full {
      flex: 1 1 auto;
    }
  }
}

.size-slider {
  position: relative;
  input {
    appearance: none;
    width: 100%;
    height: 20px;
    outline: none;
    transition: opacity 0.2s;
    margin: 0;
    background-color: transparent;
    display: block;

    &::-webkit-slider-thumb {
      appearance: none;
      width: 4px;
      height: 24px;
      border-radius: 90px;
      cursor: pointer;
      box-shadow: 0 0 0 4px white;
      background: var(--color-grey-50);

      .section.dark & {
        background: var(--color-grey-50);
        box-shadow: 0 0 0 4px var(--color-grey-90);
      }
    }

    &::-moz-range-thumb {
      appearance: none;
      width: 4px;
      height: 24px;
      border-radius: 90px;
      cursor: pointer;
      box-shadow: 0 0 0 4px white;
      background: var(--color-grey-50);

      .section.dark & {
        background: var(--color-grey-50);
        box-shadow: 0 0 0 4px var(--color-grey-90);
      }
    }

    + label {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 20px;
      background-color: var(--color-grey-30);
      clip-path: polygon(100% 0%, 100% 100%, -15% 100%);
      z-index: -1;

      .section.dark & {
        background-color: var(--color-grey-80);
      }
    }
  }
}

.slider {
  input {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: var(--color-grey-30);
    outline: none;
    margin: 0;

    &::-webkit-slider-thumb {
      appearance: none;
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      background-color: white;
      border: 6px solid var(--color-primary);
      cursor: pointer;
      border-radius: 50%;
      outline: 2px solid white;
    }

    &::-moz-slider-thumb {
      appearance: none;
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      background-color: white;
      border: 6px solid var(--color-primary);
      cursor: pointer;
      border-radius: 50%;
      outline: 2px solid white;
    }
  }
}

.block-transitions,
.block-transitions *,
.block-transitions *:before,
.block-transitions *:after {
  transition: none !important;
}

.hidden-input {
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
  display: none;
}

.section-setting-row {
  display: flex;
  align-items: center;
  height: 40px;

  .setting-name {
    flex: 1 1 auto;
  }

  .setting-value {
    display: flex;
    align-items: center;
    gap: 4px;

    .custom-button {
      width: 48px;
    }

    .slider {
      width: 152px;
    }
  }
}
